import React from 'react';
import {
    AppBar, CssBaseline, Divider, Drawer, Hidden, IconButton, Toolbar,
    Typography, List, ListItem, ListItemText, ListItemProps, ClickAwayListener,
    Paper, Button,
} from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu"
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { 
  AccountCircle, Home, People, HighlightOff, SupervisorAccount, Work, Lock, FastForward, Schedule
} from "@material-ui/icons"

import { Auth } from "aws-amplify";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiContainer-root': {
        [theme.breakpoints.down('lg')]: {
          paddingLeft: 8,
          paddingRight: 8
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: "#212121"
    },
    drawerButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      display: "flex"
    },
    content: {
      flexGrow: 1,
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(2)
      }
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1
      }
    },
    subTitle: {
      fontWeight: "bold",
      marginLeft: 8,
      flexGrow: 1
    },
    drawerItem: {
      marginLeft: 4
    },
    userDetails: {
      position: "absolute"
    },
    drawerAdmin: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexGrow: 1
    }
  }),
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
  }

interface Props {
  window?: () => Window;
  children?: React.ReactNode;
  user: any | null
  signOut: () => void;
}

export default function AppShell(props: Props) {
  const { window, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openUserDetails, setUserDetails] = React.useState<boolean>(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUserDetailsClick = () => {
    setUserDetails(!openUserDetails);
  }

  const handleUserDetailsClickAway = () => {
    setUserDetails(false);
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItemLink href="#/">
          <Home />
          <ListItemText className={classes.drawerItem} primary="Applications" />
        </ListItemLink>
        <ListItemLink href="#/interviews">
            <Schedule />
            <ListItemText className={classes.drawerItem} primary="Interviews" />
        </ListItemLink>
        <ListItemLink href="#/candidates">
          <People />
          <ListItemText className={classes.drawerItem} primary="Candidates" />
        </ListItemLink>
        <ListItemLink href="#/jobs">
          <Work />
          <ListItemText className={classes.drawerItem} primary="Jobs" />
        </ListItemLink>
        <ListItemLink href="#/data-privacy">
          <Lock />
          <ListItemText className={classes.drawerItem} primary="Data & Privacy" />
        </ListItemLink>
        <ListItemLink href="#/fasttrack-requests">
          <FastForward />
          <ListItemText className={classes.drawerItem} primary="FastTrack Queue" />
        </ListItemLink>
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.drawerButton}>
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Lowie Recruitment
          </Typography>
          <Hidden smDown>
            <Typography className={classes.subTitle} variant="h6" noWrap>
              CMS
            </Typography>
          </Hidden>
          <div>
            <IconButton color="inherit" onClick={handleUserDetailsClick}>
              <AccountCircle />
            </IconButton>
            {openUserDetails && 
              <ClickAwayListener onClickAway={handleUserDetailsClickAway}>
                <Paper className={classes.userDetails}>
                    <Button style={{width: "100%"}} onClick={() => props.signOut()}>
                      Sign Out
                      <HighlightOff style={{marginLeft: 4}}/>
                    </Button>

                </Paper>
              </ClickAwayListener>
            }
            <Hidden xsDown>
              {props.user  &&
                <span style={{fontWeight: "bold"}}>{`${props.user.username}`}</span> 
              }
            </Hidden>
            
          </div>
          
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}

            <div className={classes.drawerAdmin}>
              <div></div>
              <div>
                <Button href="#/admin">
                  <SupervisorAccount />
                  Admin
                </Button>
              </div>

            </div>
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}